import React, { useEffect } from "react";
import { Button, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import useStatusReport from "../../../hooks/useStatusReport";
import { usePostStatusUpdateAzureMutation } from "../../../api/api";
import { useTranslation } from "react-i18next";

interface SelfieConsentModalProps {
    hasConsentedToBioWebApp: boolean;
    setHasConsentedToBioWebApp: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelfieConsentModal = ({ hasConsentedToBioWebApp, setHasConsentedToBioWebApp }: SelfieConsentModalProps) => {
    const { t } = useTranslation();
    const biometricPolicy = "https://www.intellicheck.com/biometric-information-policy/";
    const [postStatusUpdateAzure] = usePostStatusUpdateAzureMutation();
    const user = useSelector((state: RootState) => state.user);
    const { token, routerVersion, language } = user;
    const url = `/biometrics-denied?token=${token}&version=${routerVersion}&language=${language}`;
    const navigate = useNavigate();
    const { sendReport } = useStatusReport();

    useEffect(() => {
        sendReport("User navigated to Biometrics accept screen");

        /** Post Azure Event Update - Started Selfie Capture */
        const statusUpdateBody = {
            module: "IDN-FacialRecognition-003",
            message: "InProgress",
            status: 1,
            error: null,
        };
        postStatusUpdateAzure(statusUpdateBody);
    }, []);

    const clickCancel = () => {
        setHasConsentedToBioWebApp(false);
        sendReport("User clicked cancel on Biometrics");
        const body = {
            module: "IDN-FacialRecognition-003",
            message:
                "Terms and Conditions By continuing, you consent to the processing of your information, including biometric information derived from the images you submit and barcodes from your identification card, for identity verification purposes, where permitted by law. For more information about the handling of your biometric information, review the Intellicheck Biometric PolicyI AgreeDeclineContinue | Terms Declined",
            error: null,
            status: 2,
        };
        postStatusUpdateAzure(body);
        navigate(url);
    };

    const clickContinue = () => {
        setHasConsentedToBioWebApp(true);
        sendReport("User clicked accept Biometrics");
        const body = {
            module: "IDN-FacialRecognition-003",
            message:
                "Terms and Conditions By continuing, you consent to the processing of your information, including biometric information derived from the images you submit and barcodes from your identification card, for identity verification purposes, where permitted by law. For more information about the handling of your biometric information, review the Intellicheck Biometric PolicyI AgreeDeclineContinue | Terms Accepted",
            error: null,
            status: 2,
        };
        postStatusUpdateAzure(body);
    };

    const clickBiometricsPolicyLink = () => {
        sendReport("User clicked Biometrics Policy link");
    };

    return (
        <>
            {!hasConsentedToBioWebApp && (
                <div className='container vh-100 bg-secondary d-flex flex-column align-items-center'>
                    <p className='text-center text-light fs-5 pt-5 mt-5'>{t("selfieConsentModal.iAgree")}</p>
                    <Stack direction='horizontal' gap={3} className='d-flex justify-content-center'>
                        <Button variant='danger' onClick={clickCancel}>
                            {t("selfieConsentModal.cancel")}
                        </Button>
                        <Button variant='primary' onClick={clickContinue}>
                            {t("selfieConsentModal.continue")}
                        </Button>
                    </Stack>

                    <a href={biometricPolicy} className='text-warning mt-3' target='_blank' rel='noreferrer' onClick={clickBiometricsPolicyLink}>
                        {t("selfieConsentModal.biometricPolicy")}
                    </a>
                    {/* <p className="text-warning mt-5">Take a selfie</p> */}
                </div>
            )}
        </>
    );
};

export default SelfieConsentModal;
