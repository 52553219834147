import React, { useEffect } from "react";
import VideoDecode from "../components/VideoDecoder/BarcodeScanner/VideoDecode";
import useStatusReport from "../hooks/useStatusReport";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";

const BarcodePage = () => {
    const { sendReport } = useStatusReport();
    const barcodeCaptureComponent = getCompanyConfig("barcodeCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to barcode capture screen");
    }, []);

    return <ErrorBoundaryWrapper>{barcodeCaptureComponent ? barcodeCaptureComponent : <VideoDecode />}</ErrorBoundaryWrapper>;
};

export default BarcodePage;
