/**
 * The `CompletePage` component represents a page indicating the completion of a task or process.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling and navigation
 * is handled using the `useNavigate` hook from React Router.
 *
 * @component
 * @example
 * // Import the CompletePage component in your application
 * import CompletePage from './CompletePage';
 *
 * // Render the CompletePage component within a Route
 * <Route path='/complete' element={<CompletePage />} />
 *
 * // When navigating to '/complete', the CompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */
import { useEffect } from "react";
import BackgroundAndLogo from "../components/BackgroundAndLogo/BackgroundAndLogo";
import useStatusReport from "../hooks/useStatusReport";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "../redux/slices/user.slice";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import { useTranslation } from "react-i18next";

const CompletePage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sendReport } = useStatusReport();

    const completeComponent = getCompanyConfig("completeComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        dispatch(setUserObject({ completed: true }));
        sendReport("User navigated to complete page");
    }, []);

    return (
        <BackgroundAndLogo>
            {completeComponent ? (
                completeComponent
            ) : (
                <div className='container h-50 d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex flex-column text-center'>
                        <h1 className='text-success fw-bold'>{t("completePage.submitted")}</h1>
                        <h3 className='fw-bold'>{t("completePage.complete")}</h3>
                    </div>
                    <p>{t("completePage.close")}</p>
                </div>
            )}
        </BackgroundAndLogo>
    );
};

export default CompletePage;
