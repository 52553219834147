import GreenskyLandingImage from "../../../assets/Greensky/GreenskyLandingImage.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useStatusReport from "../../../hooks/useStatusReport";

const GreenskyLanding = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const { sendReport } = useStatusReport();

    const clickStart = () => {
        sendReport("User clicked start data collection");
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
            <h1 className='mt-5 text-primary'>Get There Faster.</h1>
            <p className='mt-4 mb-5'>Validate your id with Intellicheck.</p>
            <img className='col-12 col-sm-10 col-md-8 col-lg-3 mb-5' src={GreenskyLandingImage} />
            <Button className='col-11 col-md-4 p-2' variant='primary' onClick={clickStart}>
                Next
            </Button>
        </div>
    );
};

export default GreenskyLanding;
