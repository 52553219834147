import React, { useEffect } from "react";
import BackgroundAndLogo from "../../components/BackgroundAndLogo/BackgroundAndLogo";
import useStatusReport from "../../hooks/useStatusReport";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const CameraPermissionsDenied = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { sendReport } = useStatusReport();

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to camera permissions denied page");
    }, []);

    const clickBack = () => {
        sendReport("User clicked Go back Button on camera permissions denied page");
        navigate(-1);
    };

    return (
        <BackgroundAndLogo>
            <div className='h-100 w-100 d-flex flex-column justify-content-start align-items-center mt-5'>
                <h3 className='text-center mb-5'>{t("cameraPermissionsDeniedPage.areYouSure")}</h3>
                <h3 className='text-center mb-5'>{t("cameraPermissionsDeniedPage.backOrClose")}</h3>
                <Button onClick={clickBack}>{t("cameraPermissionsDeniedPage.goBack")}</Button>
            </div>
        </BackgroundAndLogo>
    );
};

export default CameraPermissionsDenied;
