const FirstAmericanTitleComplete = () => {
    return (
        <div className='container h-75 d-flex flex-column justify-content-center align-items-center'>
            <div className='d-flex flex-column text-center'>
                <h1 className='text-success fw-bold'>Congratulations!</h1>
                <h3 className='fw-bold'>Your ID validation has been completed.</h3>
            </div>
            <p className='col-10 text-center lh-sm text-dark mt-5'>
                In a few moments, this
                <span className='fw-bold'> page will automatically refresh</span>, and you can continue with next steps.
            </p>
            <p className='mt-5'>
                <b>Please do not close this window.</b>
            </p>
        </div>
    );
};

export default FirstAmericanTitleComplete;
