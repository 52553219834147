/**
 * The `TruistCompletePage` component represents a page indicating the completion of a task or process for Truist.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling and navigation
 * is handled using the `useNavigate` hook from React Router.
 *
 * @component
 * @example
 * // Import the TruistCompletePage component in your application
 * import TruistCompletePage from './TruistCompletePage';
 *
 * // Render the TruistCompletePage component within a Route
 * <Route path='/complete' element={<TruistCompletePage />} />
 *
 * // When navigating to '/complete', the TruistCompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */

import { Stack } from "react-bootstrap";
import TruistCheckMark from "../../../assets/Truist/TruistCheckMark";

const TruistCompletePage = () => {
    return (
        <>
            <Stack gap={5} className='col-12 justify-content-center align-items-center'>
                <h2 className='text-center text-dark'>You're all set.</h2>

                <TruistCheckMark />

                <Stack gap={2} className='flex-column justify-content-center align-items-center'>
                    <h4 className='fw-bold col-12 text-center'>Now, let's finish your application.</h4>

                    <p className='col-10 text-center lh-sm text-dark'>
                        In a few moments, this
                        <span className='fw-bold'> page will automatically refresh</span>, and you can continue with next steps.
                    </p>
                </Stack>
            </Stack>
        </>
    );
};

export default TruistCompletePage;
