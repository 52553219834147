const TransUnionLandingPage = () => {
    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center position-relative'>
            <h1 className='mt-4 mb-4'>
                Information for Good<sup className='fs-4'>&reg;</sup>.
            </h1>
            <p className='mt-5 mb-5'>Please complete the ID verification process.</p>
            <p className='mt-5 mb-5'>You will need your driver's license or identification card to complete this process.</p>
        </div>
    );
};

export default TransUnionLandingPage;
