/**
 * The `Landing` component serves as the landing or home page of the application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the Landing component in your application
 * import Landing from './Landing';
 *
 * // Render the Landing component within a Route
 * <Route path='/' element={<Landing />} />
 *
 * // When navigating to the root URL ('/'), the Landing component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import selfieImage from "../assets/selfie-image.png";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import BackgroundAndLogo from "../components/BackgroundAndLogo/BackgroundAndLogo";
import { useEffect } from "react";
import useStatusReport from "../hooks/useStatusReport";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;

    const landingImages = getCompanyConfig("landingImages");
    const landingComponent = getCompanyConfig("landingComponent");
    const hideDefaultLanding = getCompanyConfig("hideDefaultLanding");

    const landingImage = landingImages ? landingImages[0] : selfieImage;

    const { sendReport } = useStatusReport();

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User has open the text link and navigated to the landing page");
    }, []);

    const clickStart = () => {
        sendReport("User clicked start data collection");
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <BackgroundAndLogo>
            {landingComponent ? landingComponent : <img className='col-xs-12 col-sm-10 col-md-7 col-lg-4 h-50' src={landingImage} alt='Selfie' />}
            {hideDefaultLanding ? (
                <></>
            ) : (
                <div className='mb-5'>
                    <div className='mb-1 d-flex justify-content-center'>
                        <Button variant='primary' onClick={clickStart}>
                            {t("landingPage.start")}
                        </Button>
                    </div>
                    <div>
                        <h1 className='text-center fw-bold'>
                            {captureReqs.length} - {t("landingPage.step")}
                        </h1>
                        <h2 className='text-center fw-bold'>{t("landingPage.identityValidation")}</h2>
                    </div>
                </div>
            )}
        </BackgroundAndLogo>
    );
};

export default LandingPage;
