import React from "react";
import { Stack } from "react-bootstrap";

const CommissionBeforeClosingLandingPage = () => {
    return (
        <Stack gap={1} className='justify-content-evenly text-center position-relative'>
            <h1 className='mt-4'>Dedicated to helping you grow your real-estate business.</h1>
            <p className='mt-4 mb-4'>Please complete the ID verification process.</p>
            <p className='mt-4 mb-4'>You will need your driver's license or identification card to complete this process.</p>
        </Stack>
    );
};

export default CommissionBeforeClosingLandingPage;
