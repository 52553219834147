import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// baseUrl for the server is set in the .env file
const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state: any = getState();
            const token = state?.user?.token;
            const routerVersion = state?.user?.routerVersion;
            if (token) {
                headers.set("ingestToken", token);
            }
            headers.set("routerVersion", routerVersion);
            return headers;
        },
    }),
    endpoints: (build) => ({
        postStatusUpdate: build.mutation({
            query: (params) => {
                const url = "/yahooeu/update-status";
                return {
                    url,
                    method: "POST",
                    body: {
                        statusModuleName: params.statusModuleName,
                        statusMessage: params.statusMessage,
                        statusError: params.statusError,
                        statusCode: params.statusCode,
                    },
                };
            },
        }),
        postStatusUpdateAzure: build.mutation({
            query: (params) => {
                const url = "/status/update-status";
                return {
                    url,
                    method: "POST",
                    body: {
                        message: params.message,
                        module: params.module,
                        status: params.status,
                        error: params.error,
                    },
                };
            },
        }),
        sendStatusReport: build.mutation({
            query: (params) => {
                const url = "/status/send-report";
                return {
                    url,
                    method: "POST",
                    body: {
                        url: params.url,
                        message: params.message,
                        userAgent: params.userAgent,
                    },
                };
            },
        }),
        ingestIpqsTrackerId: build.mutation({
            query: (params) => {
                const url = "/ingest/ipqs-trackerId";
                return {
                    url,
                    method: "POST",
                    body: {
                        ipqsTrackerId: params.ipqsTrackerId,
                    },
                };
            },
        }),
        fetchUserConfig: build.mutation({
            query: (params) => {
                const url = "/user/get-config";
                return {
                    url,
                    method: "POST",
                    body: {
                        token: params.token,
                    },
                };
            },
        }),
        ingestFrontImage: build.mutation({
            query: (params) => {
                const url = "/ingest/front-image";
                return {
                    url,
                    method: "POST",
                    body: {
                        frontImage: params.frontImage,
                    },
                };
            },
        }),
        ingestBackImage: build.mutation({
            query: (params) => {
                const url = "/ingest/back-image";
                return {
                    url,
                    method: "POST",
                    body: {
                        backImage: params.backImage,
                    },
                };
            },
        }),
        ingestBarcodeData: build.mutation({
            query: (params) => {
                const url = "/ingest/barcode-data";
                return {
                    url,
                    method: "POST",
                    body: {
                        barcodeData: params.barcodeData,
                    },
                };
            },
        }),
        ingestSelfieData: build.mutation({
            query: (params) => {
                const url = "/ingest/selfie-image";
                return {
                    url,
                    method: "POST",
                    body: {
                        selfieImage: params.selfieImage,
                    },
                };
            },
        }),
        // FACIA
        fetchFaciaLivenessData: build.mutation({
            query: (params) => {
                const url = "/service/fetch-facia-liveness-data";
                return {
                    url,
                    method: "POST",
                    body: {
                        redirectUrl: params.redirectUrl,
                        callbackUrl: params.callbackUrl,
                    },
                };
            },
        }),
        fetchAndIngestSelfieData: build.mutation({
            query: (params) => {
                const url = "/service/poll-and-submit-results";
                return {
                    url,
                    method: "POST",
                    body: {
                        reference_id: params.reference_id,
                    },
                };
            },
        }),
        fetchAuthIdUrl: build.mutation({
            query: (params) => {
                const url = "/service/get-authid-url";
                return {
                    url,
                    method: "POST",
                    body: {},
                };
            },
        }),
        fetchAndPostAuthIdResults: build.mutation({
            query: (params) => {
                const url = "/service/fetch-and-post-authid-results";
                return {
                    url,
                    method: "POST",
                    body: {},
                };
            },
        }),
    }),
});

export const {
    useFetchAndPostAuthIdResultsMutation,
    useFetchAuthIdUrlMutation,
    useFetchAndIngestSelfieDataMutation,
    useFetchUserConfigMutation,
    useFetchFaciaLivenessDataMutation,
    usePostStatusUpdateMutation,
    useSendStatusReportMutation,
    useIngestIpqsTrackerIdMutation,
    useIngestFrontImageMutation,
    useIngestBackImageMutation,
    useIngestBarcodeDataMutation,
    useIngestSelfieDataMutation,
    usePostStatusUpdateAzureMutation,
} = api;
