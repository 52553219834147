import { Stack } from "react-bootstrap";

type GenericCompanyLandingPageProps = {
    companyName: string;
};

function GenericCompanyLandingPage({ companyName }: GenericCompanyLandingPageProps) {
    return (
        <Stack gap={1} className='justify-content-evenly text-center position-relative'>
            <h1 className='mb-4'>Thank you for choosing {companyName}!</h1>
            <p className='mt-4 mb-4'>Please complete the ID verification process.</p>
            <p className='mt-4 mb-4'>You will need your driver's license or identification card to complete this process.</p>
        </Stack>
    );
}

export default GenericCompanyLandingPage;
