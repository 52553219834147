import React, { useEffect, useState } from "react";
import SelfieConsentModal from "./SelfieConsent/SelfieConsentModal";
import { useFetchAndPostAuthIdResultsMutation } from "../../api/api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { getNextPage } from "../../redux/slices/user.slice";
import ErrorBoundaryWrapper from "../ErrorBoundary/ErrorBoundaryWrapper";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "authid-component": AuthIdComponentProps;
        }
    }
}

interface AuthIdComponentProps extends React.HTMLProps<HTMLDivElement> {
    "data-url": string | null;
    "data-webauth": string;
}

const SelfieCapture = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language, authIdUrl } = user;
    const [hasConsentedToBioWebApp, setHasConsentedToBioWebApp] = useState(false);
    const [fetchAndPostAuthIdResults] = useFetchAndPostAuthIdResultsMutation();
    const url = `/${getNextPage(captureRequirements, "selfie")}?token=${token}&version=${routerVersion}&language=${language}`;

    const handleMessages = async (event: any) => {
        if (event.data.success && event.data.pageName === "verifiedPage") {
            const fetchAndSubmitBody = {};
            fetchAndPostAuthIdResults(fetchAndSubmitBody);
            navigate(url);
        }
    };

    useEffect(() => {
        window.removeEventListener("message", handleMessages);
        window.addEventListener("message", handleMessages);
    }, [captureRequirements]);

    useEffect(() => {}, [authIdUrl]);

    if (!hasConsentedToBioWebApp) {
        return <SelfieConsentModal setHasConsentedToBioWebApp={setHasConsentedToBioWebApp} hasConsentedToBioWebApp={hasConsentedToBioWebApp} />;
    }

    return (
        <>
            {authIdUrl ? (
                <ErrorBoundaryWrapper>
                    {" "}
                    <authid-component data-url={authIdUrl} data-webauth='true'></authid-component>{" "}
                </ErrorBoundaryWrapper>
            ) : (
                /** The AuthID Selfie URL isn't available likely because the call failed. */
                <p>No service URL</p>
            )}
        </>
    );
};

export default SelfieCapture;
