/**
 * This is the main application component that serves as the entry point for the entire application.
 *
 * It handles routing using React Router, loading WebAssembly (Wasm) resources, and rendering different
 * components based on the current route. The component also displays a loading message while Wasm is loading.
 *
 * @component
 * @example
 * // Import the App component in your application
 * import App from './App';
 *
 * // Render the App component in your application
 * ReactDOM.render(<App />, document.getElementById('root'));
 */
import React from "react";
import { useTranslation } from "react-i18next";
import IpqsTracker from "./components/IpqsTracker/IpqsTracker";
import DefaultRouter from "./routers/DefaultRouter";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "./pages/ErrorPages/ErrorPage";
import { setUserObject } from "./redux/slices/user.slice";
import { useFetchAuthIdUrlMutation, useFetchUserConfigMutation, usePostStatusUpdateAzureMutation, usePostStatusUpdateMutation } from "./api/api";
import { RootState } from "./redux/store";
import LoadingPage from "./pages/LoadingPage";
import QRCode from "react-qr-code";
import { Stack } from "react-bootstrap";

/**
 * The main application component.
 *
 * @returns {JSX.Element} The JSX element representing the main application.
 */
const App = (): JSX.Element => {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [fetchUserConfig, userConfig] = useFetchUserConfigMutation();
    const [fetchAuthIdUrl, authId] = useFetchAuthIdUrlMutation();
    const { authIdUrl: userAuthIdUrl } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const [postStatusUpdate] = usePostStatusUpdateMutation();
    const [postStatusUpdateAzure] = usePostStatusUpdateAzureMutation();

    const token = urlParams.get("token");
    const routerVersion = urlParams.get("version");
    const language = urlParams.get("language");

    const defaultEnvVersion = process.env.REACT_APP_DEFAULT_EXPERIENCE;

    // set the token and routerVersion on state
    React.useEffect(() => {
        let obj = {};
        if (routerVersion) {
            obj = { ...obj, ...{ routerVersion } };
        } else if (defaultEnvVersion) {
            obj = { ...obj, ...{ routerVersion: defaultEnvVersion } };
        }
        if (language) {
            obj = { ...obj, ...{ language } };
        }
        if (token) {
            obj = { ...obj, ...{ token: token } };
        }
        dispatch(setUserObject(obj));
    }, [token, routerVersion, language]);

    // When the token changes, get the userConfig
    React.useEffect(() => {
        if (token) {
            fetchUserConfig({ token: token });
        }

        /** Yahoo Event Status update posts to Redis / JT */
        if (routerVersion?.toLowerCase() === "yahooeu") {
            try {
                const yahooStatusUpdateBody = {
                    statusModuleName: "IDN-UI-WelcomeScreen",
                    statusMessage: "Started",
                    statusError: null,
                    statusCode: 1,
                };
                postStatusUpdate(yahooStatusUpdateBody);
            } catch (error) {
                // empty because of previous console message
                // console message index = 1
            }
        } else {
            /** Otherwise Event Status update posts to Azure */
            try {
                const statusUpdateBody = {
                    module: "IDN-UI-WelcomeScreen",
                    message: "Started",
                    status: 1,
                    error: null,
                };
                postStatusUpdateAzure(statusUpdateBody);
            } catch (error) {
                console.error(`Failed to post welcome status update.`);
            }
        }
    }, [token, fetchUserConfig]);

    // Process the userConfig
    React.useEffect(() => {
        if (!userConfig.isUninitialized && !userConfig.isLoading && !userConfig.isError && userConfig.isSuccess) {
            const { captureRequirements, expirationTime, isTokenValidForIngest } = userConfig.data?.payload;

            if (captureRequirements.includes("selfie") && !userAuthIdUrl) {
                fetchAuthIdUrl({});
            }

            const now = new Date();
            let obj = {
                captureRequirements,
                tokenExpirationTime: expirationTime,
                isTokenValid: isTokenValidForIngest,
                landingTimeStamp: now.toString(),
            };
            dispatch(setUserObject(obj));
        } else if (userConfig.isError) {
            const tokenUsed = (userConfig as any).error?.status === 498;
            if (tokenUsed) {
                navigate(`/complete?token=${urlParams.toString()}`);
            }
        }
    }, [userConfig]);

    React.useEffect(() => {
        if (!authId.isUninitialized && !authId.isLoading && !authId.isError && authId.isSuccess) {
            dispatch(setUserObject({ authIdUrl: authId?.data?.payload?.authIdUrl }));
        }
    }, [authId]);

    const handleOrientationChange = () => {
        const currentOrientationAngle = window?.screen?.orientation?.angle;
        if (currentOrientationAngle) {
            const isLandscape = Math.abs(currentOrientationAngle) === 90 || Math.abs(currentOrientationAngle) === 270;
            if (isLandscape) {
                // Alert user that landscape is not available
                alert(t("app.rotateAlert"));
            }
        }
    };

    React.useEffect(() => {
        // Add event listener for orientation change
        window.addEventListener("orientationchange", handleOrientationChange);

        // Check the initial orientation
        handleOrientationChange();

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    if (!token || userConfig.isError) {
        return <ErrorPage />;
    }

    // Show Loading page while waiting for userConfig
    if (userConfig.isUninitialized || userConfig.isLoading) {
        return <LoadingPage />;
    }

    return (
        <div className='App'>
            <div className='d-none d-lg-block'>
                <Stack direction='vertical' gap={3} className='align-items-center justify-content-center vh-100'>
                    <div>{t("app.qrCode")}</div>
                    <div>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: 250, width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </Stack>
            </div>
            <div className='d-block d-lg-none'>
                <DefaultRouter />
                {token && !userConfig.isError && !userConfig.isUninitialized && !userConfig.isLoading && <IpqsTracker />}
            </div>
        </div>
    );
};

export default App;
