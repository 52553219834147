/**
 * The `YahooEULandingPage` component serves as the landing or home page of the YahooEU application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the YahooEULandingPage component in your application
 * import YahooEULandingPage from './YahooEULandingPage';
 *
 * // Render the YahooEULandingPage component within a Route
 * <Route path='/' element={<YahooEULandingPage />} />
 *
 * // When navigating to the root URL ('/'), the YahooEULandingPage component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */
import { Button, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import useStatusReport from "../../../hooks/useStatusReport";
import { useTranslation } from "react-i18next";

const YahooEULandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const { sendReport } = useStatusReport();

    const clickStart = () => {
        sendReport("User clicked start data collection");
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    const clickIntellicheckPrivacyPolicy = () => {
        sendReport("User clicked Intellicheck Privacy Policy link");
    };

    const clickYahooEUPrivacyPolicy = () => {
        sendReport("User clicked Yahoo EU Privacy Policy link");
    };

    return (
        <>
            <Stack gap={2} className='align-items-center'>
                <h1 className='fw-bolder fs-2 mt-2'>{t("yahooLanding.welcome")}</h1>
                <div className='align-items-center text-center col-10 col-md-7 col-lg-6 col-xl-5 fs-3'>
                    <>
                        <p>{t("yahooLanding.welcome1")}</p>
                        <p>{t("yahooLanding.welcome2")}</p>
                        <p>{t("yahooLanding.welcome3")}</p>
                    </>
                    <div className='mt-5'>
                        <Button className='col-11 col-md-9 col-lg-8 col-xl-6 mb-3' variant='primary' onClick={clickStart}>
                            {t("yahooLanding.nextButton")}
                        </Button>
                        <p className='fs-6'>
                            {t("yahooLanding.acknowlege")}{" "}
                            <a
                                href='https://www.intellicheck.com/privacy-policy/'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickIntellicheckPrivacyPolicy}
                            >
                                {t("yahooLanding.privacyPolicyIntellicheck")}
                            </a>{" "}
                            {t("yahooLanding.andIn")}{" "}
                            <a
                                href='https://legal.yahoo.com/ie/en/yahoo/privacy/index.html'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickYahooEUPrivacyPolicy}
                            >
                                {t("yahooLanding.privacyPolicyYahoo")}
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default YahooEULandingPage;
