import React from "react";
import DomaLandingImg from "../../../assets/Doma/DomaLandingImg.svg";

const DomaComplete = () => {
    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center position-relative' style={{ height: "350px" }}>
            <img className='col-12 col-sm-10 col-md-8 col-lg-3 position-absolute bottom-0 mb-5' src={DomaLandingImg} alt='house' />
            <div className='d-flex flex-column text-center'>
                <h1 className='text-success fw-bold'>Congratulations!</h1>
                <h3 className='fw-bold'>Your ID validation has been completed.</h3>
            </div>
            <p className='mt-5'>
                <b>You may now close this window.</b>
            </p>
        </div>
    );
};

export default DomaComplete;
