import { useEffect } from "react";
import useStatusReport from "../../../hooks/useStatusReport";
import VideoNormalizerUI from "../../../components/VideoNormalizer/PassportCapture/VideoNormalizerUI";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const YahooEUPassportCapture = () => {
    const { sendReport } = useStatusReport();
    const routerVersion = useSelector((state: RootState) => state.app.routerVersion);

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to front capture screen");
    }, []);

    return <VideoNormalizerUI currScanSide='Front' docType='Passport' />;
    // return <VideoRecognizer />
};
export default YahooEUPassportCapture;
