import { useSendStatusReportMutation } from "../api/api";

const useStatusReport = () => {
    const [sendReportReport] = useSendStatusReportMutation();

    const sendReport = (message: string): void => {
        const url = window.location;
        const userAgent = navigator.userAgent;

        const statusReportBody = {
            url: JSON.stringify(url),
            message,
            userAgent,
        };

        sendReportReport(statusReportBody);
    };

    return { sendReport };
};

export default useStatusReport;
