/**
 * Description: Defines a Redux slice for user-related state management.
 * This slice handles user authentication and related data.
 */
import { createSlice } from "@reduxjs/toolkit";

/**
 * Define the initial state for the user slice.
 * This state includes user data obtained from user/get-config call in the useUserSlice hook.
 */
type AppState = {
    token: string | null;
    captureRequirements: string[];
    tokenExpirationTime: number | null;
    isTokenValid: boolean;
    landingTimeStamp: Date | null;
    routerVersion: string | "default";
    language: string | "en-us";
    authIdUrl: string | undefined;
    completed: boolean;
};

const initialState: AppState = {
    token: null,
    captureRequirements: [],
    tokenExpirationTime: null,
    isTokenValid: false,
    landingTimeStamp: null,
    routerVersion: "default",
    language: "en-us",
    authIdUrl: undefined,
    completed: false,
};

/**
 * Create the Redux slice for user-related state management.
 */
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserObject: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

/**
 * Export the actions defined in the user slice.
 */
export const { setUserObject } = userSlice.actions;

/**
 * Export the reducer created by the user slice.
 */
export default userSlice.reducer;

export const getNextPage = (captureRequirements: string[], currentPage: string) => {
    const index = captureRequirements.indexOf(currentPage.toLowerCase());
    return index < 0 ? "error" : index === captureRequirements.length - 1 ? "complete" : captureRequirements[index + 1];
};
