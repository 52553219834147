import React, { useEffect } from "react";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI";
import useStatusReport from "../hooks/useStatusReport";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";

const BackPage = () => {
    const { sendReport } = useStatusReport();

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to back capture page");
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <VideoNormalizerUI currScanSide={"Back"} />
        </ErrorBoundaryWrapper>
    );
};
export default BackPage;
