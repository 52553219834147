import React, { useEffect } from "react";
import BackgroundAndLogo from "../../components/BackgroundAndLogo/BackgroundAndLogo";
import useStatusReport from "../../hooks/useStatusReport";
import { useTranslation } from "react-i18next";

const TimeoutPage = () => {
    const { t } = useTranslation();
    const { sendReport } = useStatusReport();

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to timeout page");
    }, []);

    return (
        <BackgroundAndLogo>
            <div className='h-100 w-100 d-flex flex-column justify-content-start align-items-center mt-5'>
                <h3 className='text-center'>{t("timeoutPage")}</h3>
            </div>
        </BackgroundAndLogo>
    );
};

export default TimeoutPage;
