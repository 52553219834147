import { Routes, Route, useLocation } from "react-router-dom";
import MainOutlet from "../MainOutlet";
import LandingPage from "../pages/LandingPage";
import CompletePage from "../pages/CompletePage";
import SlideTransition from "../components/SlideTransition/SlideTransition";
import FrontPage from "../pages/FrontPage";
import BackPage from "../pages/BackPage";
import SelfieCapture from "../components/SelfieCapture/AuthIdComponent";
import ErrorPage from "../pages/ErrorPages/ErrorPage";
import TimeoutPage from "../pages/ErrorPages/TimeoutPage";
import FourZeroFourPage from "../pages/ErrorPages/FourZeroFourPage";
import BiometricsDeniedPage from "../pages/ErrorPages/BiometricsDeniedPage";
import CameraPermissionsDenied from "../pages/ErrorPages/CameraPermissionsDeniedPage";
import BarcodePage from "../pages/BarcodePage";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import React from "react";

const DefaultRouter = (): JSX.Element => {
    const currLocation = useLocation();
    const additionalRoutes = getCompanyConfig("additionalRoutes");

    return (
        <>
            <Routes location={currLocation} key={currLocation.pathname}>
                <Route element={<MainOutlet />}>
                    <Route
                        path='/'
                        element={
                            <SlideTransition>
                                <LandingPage />
                            </SlideTransition>
                        }
                    />

                    <Route
                        path='/front'
                        element={
                            <SlideTransition>
                                <FrontPage />
                            </SlideTransition>
                        }
                    />

                    <Route
                        path='/back'
                        element={
                            <SlideTransition>
                                <BackPage />
                            </SlideTransition>
                        }
                    />

                    <Route
                        path='/barcode'
                        element={
                            <SlideTransition>
                                <BarcodePage />
                            </SlideTransition>
                        }
                    />

                    <Route path='/selfie' element={<SelfieCapture />} />

                    <Route
                        path='/complete'
                        element={
                            <SlideTransition>
                                <CompletePage />
                            </SlideTransition>
                        }
                    />
                    <Route
                        path='/error'
                        element={
                            <SlideTransition>
                                <ErrorPage />
                            </SlideTransition>
                        }
                    />
                    <Route
                        path='/token-expired'
                        element={
                            <SlideTransition>
                                <TimeoutPage />
                            </SlideTransition>
                        }
                    />
                    <Route
                        path='/biometrics-denied'
                        element={
                            <SlideTransition>
                                <BiometricsDeniedPage />
                            </SlideTransition>
                        }
                    />
                    <Route
                        path='/camera-denied'
                        element={
                            <SlideTransition>
                                <CameraPermissionsDenied />
                            </SlideTransition>
                        }
                    />

                    {additionalRoutes?.map((route: { route: string; element: React.ReactNode }) => (
                        <Route path={route.route} element={<SlideTransition>{route.element}</SlideTransition>} />
                    ))}
                    <Route
                        path='/*'
                        element={
                            <SlideTransition>
                                <FourZeroFourPage />
                            </SlideTransition>
                        }
                    />
                </Route>
            </Routes>
        </>
    );
};

export default DefaultRouter;
