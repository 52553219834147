import { ReactNode, Component } from "react";

export type ErrorBoundaryState = {
    hasError: boolean;
};

export type ErrorBoundaryProps = {
    children: ReactNode;
    fallback?: ReactNode;
    sendReport?: (message: string) => void;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const errorToSend = `App boundary error caught. Error: ${error}, ErrorInfo: ${JSON.stringify(errorInfo)}`;

        if (this.props.sendReport) {
            this.props.sendReport(errorToSend);
        }

        const shouldTrack = process.env.REACT_APP_TRACK_JS_ON?.toLowerCase() === "true";
        shouldTrack && window.TrackJS && TrackJS?.track(errorToSend);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
