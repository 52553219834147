import { useEffect } from "react";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI";
import useStatusReport from "../hooks/useStatusReport";
import { getCompanyConfig } from "../utils/getCompanyConfig";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper";

const FrontPage = () => {
    const { sendReport } = useStatusReport();
    const frontCaptureComponent = getCompanyConfig("frontCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        sendReport("User navigated to front capture screen");
    }, []);

    return (
        <ErrorBoundaryWrapper>
            {frontCaptureComponent ? frontCaptureComponent : <VideoNormalizerUI currScanSide='Front' docType='NA_ID' />}
        </ErrorBoundaryWrapper>
    );
};
export default FrontPage;
