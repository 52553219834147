import React from "react";
import BackgroundAndLogo from "../components/BackgroundAndLogo/BackgroundAndLogo";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
    const { t } = useTranslation();
    return (
        <BackgroundAndLogo>
            <div className='h-100 w-100 d-flex flex-column justify-content-start align-items-center mt-5'>
                <h3 className='mb-4'>{t("loadingPage")}</h3>
                <div className='spinner-border text-primary' role='status' />
            </div>
        </BackgroundAndLogo>
    );
};

export default LoadingPage;
