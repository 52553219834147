/**
 * The `TruistLandingPage` component serves as the landing or home page of the Truist application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the TruistLandingPage component in your application
 * import TruistLandingPage from './TruistLandingPage';
 *
 * // Render the TruistLandingPage component within a Route
 * <Route path='/' element={<TruistLandingPage />} />
 *
 * // When navigating to the root URL ('/'), the TruistLandingPage component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */

// import { useNavigate } from "react-router";
import { Stack, Button } from "react-bootstrap";
import SelfieImg from "../../../assets/selfie-image.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useStatusReport from "../../../hooks/useStatusReport";

const TruistLandingPage = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const { sendReport } = useStatusReport();

    const clickStart = () => {
        sendReport("User clicked start data collection");
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <>
            <Stack gap={5} className='col-12 justify-content-center align-items-center'>
                <div className='col-8 col-md-3'>
                    <img src={SelfieImg} alt='Selfie' className='img-fluid' />
                </div>

                <h2 className='text-center text-dark'>Let's scan your ID and verify your identity.</h2>
                <Button className='col-11 col-md-4 p-2' variant='primary' onClick={clickStart}>
                    Next
                </Button>
            </Stack>
        </>
    );
};

export default TruistLandingPage;
