import { ReactNode } from "react";
import ErrorBoundary from "./ErrorBoundary";
import useStatusReport from "../../hooks/useStatusReport";
import ErrorPage from "../../pages/ErrorPages/ErrorPage";

type ErrorBoundaryWrapperProps = {
    children: ReactNode;
};

const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWrapperProps) => {
    const { sendReport } = useStatusReport();

    return (
        <ErrorBoundary fallback={<ErrorPage />} sendReport={sendReport}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper;
