import FirstAmericanImage from "../../../assets/FirstAmericanTitle/FirstAmericanTitleLandingImage.png";

const FirstAmericanTitleLanding = () => {
    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
            <h1 className='mt-5'>Thank you for choosing First American!</h1>
            <p className='mt-4'>Please complete the ID verification process.</p>
            <img className='col-12 col-sm-10 col-md-8 col-lg-3' src={FirstAmericanImage} alt='First American Logo' />
        </div>
    );
};

export default FirstAmericanTitleLanding;
